import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { formsService } from '@/utils/api/formsService';

export function useEnableFormUser() {
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (form_user_id: number) =>
      formsService.enableFormUser(form_user_id),
    onError: () => {
      toast.add({
        severity: 'error',
        detail: 'Wystąpił błąd podczas odblokowywania formularza',
        life: 3000,
      });

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'patients',
      });
      queryClient.invalidateQueries({ queryKey: ['forms'] });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['forms'] });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === 'patients',
      });
    },
  });

  function enableFormUser(form_user_id: number) {
    mutate(form_user_id);
  }

  return {
    enableFormUser,
    isPending,
  };
}
